import React, { useRef, useState } from "react";
import { SliderWrapperPopup } from "./style";
import IconButton from "@mui/material/IconButton";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CirculerProgress from "../UI-components/circulerprogress";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import axios from "axios";
import { REACT_APP_reCAPTCHA_SITE_KEY, abAPI } from "../../../config";
import { Box, Checkbox, TextField } from "@mui/material";
import dictonary from "../../../dictionary";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function joinme2form(props) {
  const [showProgress, setShowProgress] = useState(0);
  const [element, setElement] = React.useState(null);
  const [status, setStatus] = React.useState("home");
  const [flag, setFlag] = useState("");
  const [show, setshow] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
  } = useForm();
  const requiredFields = ["firstName", "lastName", "email"];
  var ReactTelInput = require("react-telephone-input");
  const isSubmitDisabled =
    requiredFields.some((field) => !watch(field)) ||
    errors?.email?.type === "pattern";
  const [isSubmitted, setIsSubmitted] = useState(false);
  const captchaRef = useRef(null);

  const [isMobile, setIsMobile] = React.useState();
  var ReactTelInput = require("react-telephone-input");
  
  document.querySelectorAll('[aria-labelledby=":r0:"]').forEach(function(element) {
    element.setAttribute('aria-labelledby', 'dialogSlideTrue');
  });



  React.useEffect(() => {
    if (props.open) {
      let x = setTimeout(() => setshow(true), 250);
    }
  }, [props.open]);

  React.useEffect(() => {
    let ele = document.querySelector("#swipeme");
    if (ele) {
      setElement(ele);
    }
  }, [element]);
  window.onresize = resize;

  function resize() {
    if (window.innerWidth < 500) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  } 


 
  const ulElement = document.querySelectorAll(' #menu-phoneType   ul ');
  // ulElement.setAttribute("aria-labelledby","true")

  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  };
  const handleRegistration = async (data) => {
    // e.preventDefault();
    setIsSubmitted(true);
    setShowProgress(true);

    const token = await captchaRef.current.executeAsync();
    captchaRef.current.reset();

    if (!token) {
      console.log("Failed to retrieve reCAPTCHA token.");
    }

    data.captcha = token;
    data.Country = 'US';
    axios
        //.post(abAPI + "/Users/addUser", data) // restore for AWS and remove header
      .post(abAPI, data, {headers: { "content-type": false }})
      .then((response) => {
        setShowProgress(false);
        if (response?.data?.message === "User Already Exists") {
          CounterUpdater("Already_In");
        } else {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "Subscribe Complete",
            message: "Subscribed Successfully",
          });
          CounterUpdater("success");
        }
      })
      .catch((err) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "SubmitFailure",
          message: err?.message,
        });
        CounterUpdater("error");
      });
  };
  const handleError = (errors) => {};

  const CounterUpdater = (name) => {
    props.currentComponentFun(name);
  };

  const normalizeInput = (value, previousValue) => {
    if (!value) return value;

    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;

      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };

  const handleChangeFlag = (e) => {
    setFlag((flag) => normalizeInput(e.target.value, flag));
  };

  function detectswipe(el, func) {
    if (window.innerWidth < 1440 && window.innerWidth < window.innerHeight) {
      let swipe_det = new Object();
      swipe_det.sX = 0;
      swipe_det.sY = 0;
      swipe_det.eX = 0;
      swipe_det.eY = 0;
      var min_x = 20; //min x swipe for horizontal swipe
      var max_x = 40; //max x difference for vertical swipe
      var min_y = 40; //min y swipe for vertical swipe
      var max_y = 50; //max y difference for horizontal swipe
      var direc = "";
      let ele = el;
      ele.addEventListener(
        "touchstart",
        function (e) {
          var t = e.touches[0];
          swipe_det.sX = t.screenX;
          swipe_det.sY = t.screenY;
        },
        false
      );
      ele.addEventListener(
        "touchmove",
        function (e) {
          e.preventDefault();
          var t = e.touches[0];
          swipe_det.eX = t.screenX;
          swipe_det.eY = t.screenY;
        },
        false
      );
      ele.addEventListener(
        "touchend",
        function (e) {
          //horizontal detection
          if (
            (swipe_det.eX - min_x > swipe_det.sX ||
              swipe_det.eX + min_x < swipe_det.sX) &&
            swipe_det.eY < swipe_det.sY + max_y &&
            swipe_det.sY > swipe_det.eY - max_y
          ) {
            if (swipe_det.eX > swipe_det.sX) direc = "r";
            else direc = "l";
          }
          //vertical detection
          if (
            (swipe_det.eY - min_y > swipe_det.sY ||
              swipe_det.eY + min_y < swipe_det.sY) &&
            swipe_det.eX < swipe_det.sX + max_x &&
            swipe_det.sX > swipe_det.eX - max_x
          ) {
            if (swipe_det.eY > swipe_det.sY) direc = "d";
            else direc = "u";
          }

          if (direc != "") {
            if (typeof func == "function") func(el, direc);
          }
          direc = "";
        },
        false
      );
    }
  }

  const handleselect=()=>{
  }
  function myfunction(el, d) {
    if (d == "d") {
      props.close();
    }
  }

  element != null && detectswipe(element, myfunction);
  let image_data = dictonary.Pages.landing_page.metadata;

  return (
    <SliderWrapperPopup
    id="dialogSlideTrue"
      style={{
        padding: props.from == "benifit" ? "0px" : "",
        overflow: "scroll",
      }}
    >
      <div
        className="slider-content join-form"
        style={{ overflow: "none" }}
        id={"scrollerdiv"}
      >
        <div className="slider-header drawer" id={"swipeme"}>
          {window.innerWidth < 650 && (
            <Box className="mobile-swiper-design" onClick={props.close}></Box>
          )}
          {window.innerWidth > 650 && (
            <IconButton
              edge="start"
              color="inherit"
              style={{ padding: 0 }}
              onClick={props.close}
              aria-label="Close Form"
            >
              {/* <CloseIcon /> */}
              <KeyboardArrowDownIcon
                style={{ fontSize: "28px", padding: "0px" }}
              />
            </IconButton>
          )}
          <div className="join-text">
            <div className="join-content">
              <img
                loading="lazy"
                // src="./images/for_the_music.png"
                src={image_data.app_logo_img}
                alt={"for the music image"}
              />
            </div>
          </div>
        </div>
        <p className="font-Zuume-56">Insider Access</p>
        <div className="insider-form-div">
          <div className="start-text">
            <p className="font-Zuume-56" id={"swipeme-text"}>
              Starts Here
            </p>
            <p className="font-poppins-regular joinmusic-text">
              Join us for exclusive offers that get you even closer to the
              music. Enter for a chance to win VIP tickets, artist collabs,
              giveaways and more.
            </p>
            <form
              className="insider-form insider-form-container-pop-up"
              onSubmit={handleSubmit(handleRegistration, handleError)}
            >
              <div className="fields-forms" style={{display:"flex",justifyContent:"space-between",width:"100%"}} >

              <div className="insider-form-left insider-form-pop-up">
                <div class="form__group field">
                  <input
                    type="input"
                    class="form__field"
                    placeholder="firstName"
                    name="firstName"
                    id="firstName"
                    {...register("firstName", {
                      required: "firstName is required",
                    })}
                    maxLength={200}
                  />
                  <label
                    for="firstName"
                    class="form__label font-poppins-regular"
                  >
                    First Name <span>(required)</span>
                  </label>
                </div>
                <div class="form__group field">
                  <input
                    type="input"
                    class="form__field"
                    placeholder="lastName"
                    name="lastName"
                    id="lastName"
                    {...register("lastName", {
                      required: "lastName is required",
                    })}
                    maxLength={200}
                  />
                  <label
                    for="lastName"
                    class="form__label font-poppins-regular"
                  >
                    Last Name <span>(required)</span>
                  </label>
                </div>
                <div class="form__group field">
                  <input
                    type="input"
                    class="form__field"
                    style={
                      errors.email && errors.email.type === "pattern"
                        ? { borderBottom: "1px solid red" }
                        : {}
                    }
                    placeholder="email"
                    name="email"
                    id="email"
                    {...register("email", {
                      onChange: (e) => {
                        trigger("email");
                      },
                      required: "email is required",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "Please enter a valid email ",
                      },
                    })}
                    onBlur={() => {
                      trigger("email");
                    }}
                    maxLength={200}
                  />
                  <label for="email" class="form__label font-poppins-regular">
                    Email Id <span>(required)</span>
                  </label>
                  {errors.email && errors.email.type === "pattern" && (
                    <span className="error-msg">
                      {errors.email.message}
                      <ErrorOutlineOutlinedIcon
                        style={{ marginLeft: "8px" }}
                      />{" "}
                    </span>
                  )}
                </div>
              </div>
              <div className="insider-form-right insider-form-pop-up">
                <div class="form__group field">
                  <input
                    type="input"
                    class="form__field font-poppins-regular"
                    placeholder="promocode"
                    name="promocode"
                    id="promocode"
                    {...register("promocode")}
                    maxLength={20}
                  />
                  <label
                    for="promocode"
                    class="form__label font-poppins-regular"
                  >
                    Promo Code <span>(Optional)</span>
                  </label>
                </div>
                <div
                  style={{ padding: "0px" }}
                  class="form__group field select-field "
                >
                  <p style={{ fontSize: "13px", marginBottom: "5px" }}>
                    Phone (Optional)
                  </p>
                  <div style={{ display: "flex" }}>
                    {/* <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <Select
                        labelId=""
                        id="phoneType"
                        value={status}
                        {...register("phoneType")}
                        label="Select"
                        onChange={(e) => handleChangeStatus(e)}
                      >
                        <MenuItem aria-disabled="true" value={"home"}>Home</MenuItem>
                        <MenuItem  aria-disabled="true"value={"mobile"}>Cell</MenuItem>
                      </Select>
                    </FormControl> */}

                      <select
                        labelId=""
                        aria-label="Select Phone Type"
                        id="phoneType"
                        value={status}                       
                        {...register("phoneType")}
                        label="Select"
                        onChange={(e) => handleChangeStatus(e)}>
                      <option  value={"home"}>Home</option>
                      <option value={"mobile"}>Cell</option>

                    </select>
                    <div className="static-flag">
                      <div className="flag-code">
                        <img src="./images/flag-united-states-of-america.png" alt="" />
                        <p style={{ marginRight: "5px" }}>+1</p>
                      </div>
                      <TextField
                        variant={"standard"}
                        InputProps={{disableUnderline: true}}
                        id="phone"
                        name="phone"
                        value={flag}
                        style={{ padding: "0px" }}
                        placeholder="(123)-456-7890"
                        {...register("phone")}
                        onChange={(e) => handleChangeFlag(e)}
                      ></TextField>
                    </div>
                  </div>
                </div>
                <div>
                  <ReCAPTCHA
                    sitekey={REACT_APP_reCAPTCHA_SITE_KEY}
                    size="invisible"
                    ref={captchaRef}
                  />
                </div>
              </div>
              </div>
              <div>

              {
                <div class="form_check">
                  <Checkbox
                    {...register("TandC")}
                    className="input-check"
                    color={"primary"}
                    type="checkbox"
                    inputProps={{ 'aria-labelledby': 'TandCond' }}
                  />
                  {/* <input
                      className="input-check"
                      type="checkbox"
                      {...register("TandC", { required: "TandC is required" })}
                      className="input-check"
                      type="checkbox"
                    /> */}
                  <p className="font-poppins-regular confirm-text" id="TandCond">
                    Yes, I would like to receive emails from Sony Electronics
                    about products and services that may be of interest to me.
                    By clicking Submit, I agree to the{" "}
                    <a
                      target="_blank"
                      style={{ pointerEvents: show ? "auto" : "none" }}
                      href="https://electronics.sony.com/terms-conditions"
                    >
                      Terms of Service
                    </a>{" "}
                    and
                    <a
                      target="_blank"
                      style={{ pointerEvents: show ? "auto" : "none" }}
                      href="https://electronics.sony.com/privacy-policy"
                    >
                      Privacy Policy
                    </a>{" "}
                    and confirm that I am a US resident.
                    <a
                      target="_blank"
                      style={{ pointerEvents: show ? "auto" : "none" }}
                      href="https://electronics.sony.com/FinancialNotice"
                    >
                      Financial Incentive Notice
                    </a>{" "}
                    (optional)
                  </p>
                </div>
              }
              <div
                className="submit-form-btn"
                style={{ display: "flex", justifyContent: "center" ,width:"100%"}}
              >
                <button
                  type="submit"
                  className={
                    isSubmitDisabled || isSubmitted
                      ? "subscribe-disable"
                      : "subscribe-btn"
                  }
                  aria-label={"Submit Form"}
                  style={{ cursor: "pointer", fontWeight: "normal" }}
                  disabled={isSubmitDisabled || isSubmitted}
                >
                  {showProgress ? (
                    <CirculerProgress size={"small"} />
                  ) : (
                    "subscribe"
                  )}
                </button>
              </div>
</div>

            </form>
          </div>
        </div>
      </div>
    </SliderWrapperPopup>
  );
}
